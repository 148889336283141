import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
// import Slider from "./Slider";

const LatestBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 6
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          frontmatter {
            date
            description
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90, webpQuality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="section" id="blog">
      <h3 className="section-title container">Latest Blogs</h3>
      <div className="container blog-container">
        <div className=" row">
          {data.allMarkdownRemark.nodes.map(node => (
            <Link
              key={node.frontmatter.slug}
              to={`/blog/${node.frontmatter.slug}`}
            >
              <div className="col">
                <div className="latest-blog">
                  <Img
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                    alt={node.frontmatter.title}
                  />
                  <p className="lg-text" style={{ paddingRight: 30 }}>
                    {node.frontmatter.title}
                  </p>
                  <p className="text">{node.frontmatter.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestBlog;
