import React from "react";
// import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Header from "../components/Header";
// import Works from "../components/Works";
// import Medium from "../components/Medium";
import Github from "../components/Github";
import About from "../components/About";
import GetInTouch from "../components/GetInTouch";
import LatestBlog from "../components/LatestBlog";

const IndexPage = () => (
  <Layout>
    <SEO title="Vijit Ail" />
    <Header />
    {/* <Works /> */}
    <Github />
    <About />
    <LatestBlog />
    {/* <Medium /> */}
    <GetInTouch />
  </Layout>
);

export default IndexPage;
