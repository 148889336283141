import React from "react";

const Slider = ({ children, className }) => (
  <div className={`slider-container container ${className}`}>
    {children.map((child, idx) => (
      <div key={idx} className="slide">
        {child}
      </div>
    ))}
  </div>
);

export default Slider;
