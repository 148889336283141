import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "./Slider";

const Github = () => {
  const data = useStaticQuery(graphql`
    {
      githubData {
        data {
          viewer {
            login
            pinnedItems {
              edges {
                node {
                  description
                  id
                  name
                  url
                  languages {
                    nodes {
                      color
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="section">
      <h3 className="section-title container">Github Showcase</h3>
      <Slider className="github-slider">
        {data.githubData.data.viewer.pinnedItems.edges.map(({ node }) => (
          <a
            key={node.id}
            href={node.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="github">
              <h4 className="repo-title">{node.name}</h4>
              <p className="repo-description">{node.description || " "}</p>
              <div className="repo-language">
                <span
                  className="repo-language-color"
                  style={{ backgroundColor: node.languages.nodes[0].color }}
                ></span>
                <span>{node.languages.nodes[0].name}</span>
              </div>
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default Github;
