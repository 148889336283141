import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import LinkedinIcon from "../images/svg/linkedin.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "me.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="section about">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="section-title">
              <span role="img" aria-label="hello emoji">
                👋
              </span>{" "}
              Hey There!
            </h3>
            <p className="text">
              I'm <span className="text-primary text-bold name">Vijit Ail</span>
              , a Software Engineer based in Mumbai. I'm currently working at
              &nbsp;
              <a
                href="https://toothsi.in/"
                className="text-toothsi"
                target="_blank"
                rel="noopener noreferrer"
              >
                toothsi
              </a>
              . When I'm not at work, I write articles on React and JavaScript.
            </p>
            <p>
              <a
                href="https://in.linkedin.com/in/vijit-ail-376885179"
                className="git-cta"
                title="Linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon
                  style={{ height: 30, width: 30, marginRight: 10 }}
                />{" "}
                Connect on Linkedin
              </a>
            </p>
            <h3
              className="section-title"
              style={{ fontSize: 28, marginBottom: 12 }}
            >
              Skills
            </h3>
            <div className="skills-container">
              <div className="skill">JavaScript</div>
              <div className="skill">React</div>
              <div className="skill">NodeJs</div>
              <div className="skill">NestJs</div>
              <div className="skill">Serverless</div>
              <div className="skill">Laravel</div>
              <div className="skill">React Native</div>
              <div className="skill">CSS</div>
              <div className="skill">PHP</div>
            </div>
          </div>
          <div className="col">
            <Img
              fluid={data.image.childImageSharp.fluid}
              style={{
                maxWidth: 400,
                borderRadius: 15,
                display: "block",
                margin: "auto",
              }}
              alt="This is me"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
